export const environment = {
    production: true,
    env_name: 'sandbox',
    apiUrl: 'https://console-api-sbx.fintecture.com',
    assetsUrl: 'https://assets-sbx.fintecture.com',
    firebase_config: {
        apiKey: 'AIzaSyAFT_JtEAIsn9uXCyvDmTyWpDMq1wv2sY0',
        authDomain: 'fintecture-sandbox.firebaseapp.com',
        databaseURL: 'https://fintecture-sandbox.firebaseio.com',
        projectId: 'fintecture-sandbox',
        storageBucket: 'fintecture-sandbox.appspot.com',
        messagingSenderId: '248068616343'
    },
    enableIntercomChat: true,
    intercomId: 'bcejeh1x',
    mandatoryLoginDomain: ['@fintecture.com', '@socgen.com', '@verifone.com', '@bugbounty.com', '@ext.fintecture.com'],
    dd: {
        site: 'datadoghq.eu',
        token: 'pub4ce4c617ffdefde19442670685efba31',
    },
};
  