import { SafeNum, safeSum } from '@fintecture-npm/safenum';
import { FRAUDULENT_CASES } from '../constants/payment-status';
import { Transaction } from './transaction';
import { BENEFICIARY_TYPES } from '../constants/beneficiary-types-constants';
export interface PaymentHistoryEvent {
    status: string;
    statusDate?: string;
    type: 'success' | 'warning' | 'error' | 'waiting' | 'cancelled';
    statusText?: string;
    explanationText?: string;
}

export interface PaymentTimelineEvent {
    status: string;
    statusDate: string;
    userName?: string;
}

export interface RefundSession {
    amount: number;
    sessionId: string;
}

export class Payment {
    sessionId: string;
    environment: 'production' | 'sandbox';
    reference: string;
    amount: number;
    originalAmount: number;
    invoiceReference: string;
    netAmount: number;
    receivedAmount: number;
    type: string;
    status: string;
    bnplCustomStatus: string;
    received: boolean;
    transferState: string;
    connectUrl: string;
    application: string;
    application_id: string;
    bankName: string;
    unsuccessfulReason: string;
    createdAt: Date;
    paymentCreatedDate?: Date;
    expiryDate: Date;
    dueDate: Date;
    scheme: 'SEPA' | 'INSTANT_SEPA';
    psu: {
        name: string,
        email: string,
        phone: string
    };
    dataForRefund: {
        delegated: boolean,
        psuIbanKnown: boolean,
        refundStatus: string,
        refundAmount: number
    };
    beneficiary: {
        accountId: string,
        commercialHolderName?: string,
        beneficiaryName?: string,
        type: 'internal' | 'internal_acquiring' | 'external',
        isVirtual: boolean
    };
    createdBy: {
        name: string,
        mail: string
    };
    rtp_method: string;
    virtualBeneficiary: {
        iban: string;
    };
    history: PaymentHistoryEvent[];
    timeline: PaymentTimelineEvent[];
    bnpl?: {
        dueDate?: string;
        originalAmount?: string;
        externalProviderVersion?: string;
    };
    transferReason?: string;
    transferReasonCustom?: string;
    fraudDetails?: string;
    transactions?: Transaction[];
    actionRequired?: boolean;
    refundSessions?: any[];

    constructor(obj: any) {
        this.sessionId = obj.sessionId;
        this.environment = obj.environment;
        this.reference = obj.reference;
        this.amount = obj.amount;
        this.originalAmount = obj.original_amount;
        this.invoiceReference = obj.invoice_reference;
        this.netAmount = obj.net_amount;
        this.receivedAmount = obj.receivedAmount;
        this.type = obj.type?.name;
        this.status = obj.status;
        this.received = obj.received;
        this.transferState = obj.transferState;
        this.connectUrl = obj.connect.link;
        this.expiryDate = obj.connect.expiry_date;
        this.dueDate = obj.connect.due_date;
        this.application = obj.application.name;
        this.application_id = obj.application.id;
        this.bankName = obj.bankName;
        this.unsuccessfulReason = obj.unsuccessfulReason;
        this.createdAt = obj.createdAt;
        this.paymentCreatedDate = obj.paymentCreatedDate;
        this.scheme = obj.scheme;
        this.psu = {
            name: obj.psu.name || 'N/A',
            email: obj.psu.email || 'N/A',
            phone: obj.psu.phone_number
        };
        if (obj.dataForRefund) {
            this.dataForRefund = {
                delegated: obj.dataForRefund.delegated,
                psuIbanKnown: obj.dataForRefund.psuIbanKnown,
                refundStatus: obj.dataForRefund.refundStatus,
                refundAmount: obj.dataForRefund.refundAmount
            };
        }
        if (obj.createdBy) {
            this.createdBy = {
                name: [obj.createdBy.firstName, obj.createdBy.lastName].join(' '),
                mail: obj.createdBy.mail
            };
        }
        if (obj.beneficiary) {
            this.beneficiary = {
                accountId: obj.beneficiary.accountId,
                type: obj.beneficiary.type,
                isVirtual: obj.beneficiary.isVirtual,
                commercialHolderName: obj.beneficiary.commercialHolderName,
                beneficiaryName: obj.beneficiary.beneficiaryName,
            };
        }
        this.rtp_method = obj.type.rtp_method;
        this.bnplCustomStatus = obj.bnpl_status;
        if (obj.virtual_beneficiary) {
            this.virtualBeneficiary = {
                iban: obj.virtual_beneficiary.iban
            };
        }
        this.history = obj.history || [];
        this.timeline = obj.timeline || [];
        if (obj.bnpl) {
            this.bnpl = {
                dueDate: obj.bnpl?.dueDate || undefined,
                originalAmount: obj.bnpl?.originalAmount || undefined,
                externalProviderVersion: obj.bnpl?.externalProviderVersion || undefined,
            };
        }
        this.transferReason = obj.transfer_reason;
        this.transferReasonCustom = obj.transfer_reason_custom;
        this.fraudDetails = obj.fraud_details;   
        this.transactions = (obj.transactions || []).map((transaction) => new Transaction(transaction));
        this.actionRequired = obj.action_required;
        this.refundSessions = obj.refund_sessions || [];
    }

    // Payment Types
    isRTP(): boolean {
        return this.type === 'RequestToPay';
    }

    isRefund(): boolean {
        return this.type === 'Refund';
    }

    isPayByBank(): boolean {
        return this.type === 'PayByBank';
    }

    isRequestForPayout(): boolean {
        return this.type === 'RequestForPayout';
    }

    isPayout(): boolean {
        return this.type === 'Payout';
    }

    isManualTransfer(): boolean {
        return this.type === 'ManualTransfer';
    }

    isIncomingTransfer(): boolean {
        return this.type === 'IncomingTransfer';
    }

    isNetTerms(): boolean {
        return this.type === 'NetTerms';
    }

    isRtpReplayable(): boolean {
        return this.isRTP() && (this.isWaiting() || this.isUnsuccessful()) && ['email'].includes(this.rtp_method);
    }

    isVibanViewable(): boolean {
        return (this.isManualTransfer() || this.isIncomingTransfer()) && !!this.virtualBeneficiary?.iban;
    }

    // Status
    isWaiting(): boolean {
        return this.status === 'payment_waiting';
    }

    isPending(): boolean {
        return this.status === 'payment_pending';
    }

    isUnsuccessful(): boolean {
        return this.status === 'payment_unsuccessful';
    }

    isCreated(): boolean {
        return this.status === 'payment_created';
    }

    isCancelled(): boolean {
        return this.status === 'payment_cancelled' || this.status === 'payment_expired';
    }

    isIbanRequired(): boolean {
        return this.status === 'iban_required';
    }

    isCreatedOrWaiting(): boolean {
        return this.isCreated() || this.refundWaiting();
    }

    isPendingValidation(): boolean {
        return this.status === 'pending_validation';
    }

    isOverpaid(): boolean {
        return this.status === 'payment_overpaid';
    }

    isPartial(): boolean {
        return this.status === 'payment_partial';
    }

    isCovered(): boolean {
        return this.status === 'payment_covered';
    }

    receivedAmountMatches(): boolean {
        if (this.isManualTransfer() && this.isCreated() && this.receivedAmount !== this.amount) {
            return false;
        }
        return !this.isOverpaid() && !this.isPartial();
    }

    isCreatedOrPending(): boolean {
        return this.isCreated() || this.isPending();
    }

    dueDateIsBelowTheLimit(): boolean {
        if (!this.bnpl?.dueDate) {
            return false;
        }
        
        const dueDateTime = new Date(this.bnpl.dueDate).getTime();
        const currentDateTime = new Date().getTime();
        const differenceMilliseconds = currentDateTime - dueDateTime;
        const differenceDays = differenceMilliseconds / (1000 * 60 * 60 * 24); // Convert the difference into days      
        return differenceDays >= 15;
    }

    // Transfer states

    isSentState(): boolean {
        return this.transferState === 'sent';
    }

    isCompletedState(): boolean {
        return this.transferState === 'completed';
    }

    isRefundWatingWithLink(): boolean {
        return this.isRefund() && ['payment_waiting', 'payment_unsuccessful'].includes(this.status) && !!this.getLink();
    }

    isRejectedState(): boolean {
        return this.transferState === 'rejected';
    }

    // Associated refund payment status
    refundInitiated(): boolean {
        return this.dataForRefund?.refundStatus === 'refund_initiated';
    }

    refundAccepted(): boolean {
        return this.dataForRefund?.refundStatus === 'refund_accepted';
    }

    refundWaiting(): boolean {
        return this.dataForRefund?.refundStatus === 'refund_waiting';
    }

    refundCreated(): boolean {
        return ['refund_created', 'refund_partial', 'refund_pending'].includes(this.dataForRefund?.refundStatus);
    }

    refundableAmount(): number {
        return new SafeNum(this.receivedAmount || this.amount).substract(this.dataForRefund?.refundAmount || 0).value;
    }

    isFullyRefunded(): boolean {
        return this.refundableAmount() === 0;
    }

    internalBeneficiary(): boolean {
        return this.beneficiary?.type === BENEFICIARY_TYPES.PAYMENT_ACCOUNT;
    }

    acquiringBeneficiary(): boolean {
        return this.beneficiary?.type === BENEFICIARY_TYPES.PAYMENT_ACQUIRING;
    }

    acquiringOrInternalBeneficiary(): boolean {
        return this.internalBeneficiary() || this.acquiringBeneficiary();
    }

    settlementReceived(): boolean {
        return !this.acquiringOrInternalBeneficiary() || this.received;
    }

    hasSwiftTransactions(): boolean {
        return this.transactions?.some((transaction) => transaction.isSwiftIct());
    }

    paymentReceived(): boolean {
        return this.isPayout() ? (this.isSentState() || this.isCompletedState()) : this.settlementReceived();
    }

    refundable(): boolean {
        return (this.settlementReceived() || this._fraudulentRefundable()) && !this.isCovered() && !this.hasSwiftTransactions();
    }
    
    private _fraudulentRefundable(): boolean {
        return this.fraudDetails === FRAUDULENT_CASES.FRAUD_SUSPECTED_NO_OVERRIDE || this.transferReason === 'fraudulent_originated';
    }

    refundAvailable(): boolean {
        return (this.isCreatedOrWaiting() || !this.receivedAmountMatches()) 
            && (this.isRTP() || this.isPayByBank() || this.isManualTransfer() || this.isIncomingTransfer() || this.isNetTerms() || this.refundWaiting()) 
            && !this.isFullyRefunded();
    }

    showPaymentTracking(): boolean {
        return (this.isCreatedOrWaiting() || !this.receivedAmountMatches())
            && ((this.acquiringOrInternalBeneficiary() && (this.isPayByBank() || this.isRTP() || this.isManualTransfer() || this.isIncomingTransfer() || this.isNetTerms())) || this.isPayout() || this.isRequestForPayout() ||this.refundWaiting());
    }

    payoutSent(): boolean {
        return this.isSentState() && this.isPayout();
    }

    pendingRtp(): boolean {
        return this.isRTP() && !(this.isCreated() || this.isCancelled() || !this.receivedAmountMatches());
    }

    pendingRequestForPayout(): boolean {
        return this.isRequestForPayout() && this.isIbanRequired();
    }

    activeNetTerms(): boolean {
        return this.isNetTerms() && !this.isCancelled();
    }

    waitingManualTransfer(): boolean {
        return this.isManualTransfer() && this.isWaiting();
    }

    showLinkDetails(): boolean {
        return this.showLink() || this.showPrint();
    }

    showLink(): boolean {
        return !this.refundWaiting() && (this.pendingRtp() || this.pendingRequestForPayout() || this.isRefundWatingWithLink() || this.activeNetTerms() || this.waitingManualTransfer()) && !!this.getLink() && !this.isCovered();
    }

    showQrCode(): boolean {
        return this.isRTP() || this.isRequestForPayout() || this.isManualTransfer();
    }

    showPrint(): boolean {
        return (this.isRTP() || this.isManualTransfer()) && 
            (this.isWaiting() || this.isPending() || this.isCreated() || this.isPartial() || this.isOverpaid()) &&
            this.rtp_method !== 'sms' &&
            (this.beneficiary.type === 'internal' || 
            (this.beneficiary.type === 'internal_acquiring' && !!this.beneficiary.commercialHolderName));
    }

    getLink(): string {
        return this.connectUrl;
    }

    getPaymentTrackingBadge(): string {
        return this.paymentReceived() ? 'success' : this.isRejectedState() ? 'error' : 'warning';
    }

    getPaymentTrackingText(): string {
        return this.paymentReceived() ? 'main.payments.attributes.received' : this.isRejectedState() ? 'main.payments.attributes.rejected' : 'main.payments.attributes.upcoming';
    }

    isCancellable(): boolean {
        if (this.isRTP()) {
            return this.isWaiting() || this.isUnsuccessful();
        } else if (this.isRequestForPayout()) {
            return this.isIbanRequired();
        } else if (this.isRefund()) {
            return this.isWaiting();
        } else if (this.isNetTerms() && this.isNetTermsEditable()) {
            return true;
        } else if (this.isManualTransfer()) {
            return this.isPending() || this.isWaiting();
        }
    }

    requestForPayoutValidation(): boolean {
        return this.isPendingValidation() && this.isRequestForPayout();
    }

    // Net Terms Editable

    isNetTermsEditable(): boolean {
        return this.isAmountEditable() || this.isInvoiceReferenceEditable();
    }

    isNetTermsV2(): boolean {
        return this.bnpl?.externalProviderVersion === '2';
    }

    isAmountEditable(): boolean {
        if (
            !this.bnpl?.dueDate || this.bnpl?.originalAmount && +this.bnpl?.originalAmount !== this.amount
        ) {
            return false;
        }

        const today = new Date();
        const dueDate = new Date(this.bnpl?.dueDate);
        dueDate.setDate(dueDate.getDate() - 5);

        if (today.getTime() >= dueDate.getTime()) {
            return false;
        }

        if (!['provider_required', 'sca_required', 'onboarding_pending', 'payment_waiting', 'order_created', 'sdd_accepted', 'payment_pending'].includes(this.status)) {
            return false;
        }

        return true;
    }

    isInvoiceReferenceEditable(): boolean {
        if (!this.bnpl?.dueDate || this.invoiceReference) {
            return false;
        }

        const today = new Date();
        const dueDate = new Date(this.bnpl.dueDate);
        dueDate.setDate(dueDate.getDate() + 15);

        if (today.getTime() > dueDate.getTime()) {
            return false;
        }

        return true;
    }

    // Replay the webhook

    isReplayableWebhooks(): boolean {
        return this.isRefund() || this.isRTP() || this.isPayByBank() || this.isManualTransfer() || this.isIncomingTransfer();
    }

    // Getters

    getOverpaidAmount(): number {
        if (!this.isOverpaid()) {
            return 0;
        }

        return new SafeNum(this.receivedAmount).substract(this.dataForRefund?.refundAmount || 0).substract(this.amount).value;
    }

    getRtpMethod(): string {
        switch (this.rtp_method) {
            case 'email':
                return 'Email';
            case 'link':
                return 'Link';
            case 'sms':
                return 'SMS';
            default:
                return;
        }
    }

    getPsuEmails(): string[] {
        return this.psu.email.split(',');
    }

    // Simulate events
    
    isSimulable(): boolean {
        return this.isManualTransfer() && (this.isCreated() || this.isPending() || this.isWaiting() || this.isOverpaid() || this.isPartial() || this.isUnsuccessful());
    }

    // Transactions

    getTransactionsAmount(): string {
        return safeSum(this.transactions.map((transaction) => transaction.amount));
    }

    showSummary(): boolean {
        return (this.isManualTransfer() || this.isRTP() || this.isPayByBank()) &&
            (this.transactions.length > 0 || this.refundSessions?.length > 0) &&
            this.timeline.length > 0;
    }

    showScheme(): boolean {
        return this.scheme && this.isCreatedOrPending() && !this.isManualTransfer() && !this.transactions?.length;
    }
}
