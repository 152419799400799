import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { State } from 'src/app/shared/lib/state';
import { Payment } from 'src/app/shared/models/payment';
import { PaymentSection } from 'src/app/shared/models/payment-section';
import { CompaniesService } from 'src/app/shared/services/companies/companies.service';
import { LanguageService } from 'src/app/shared/services/language/language.service';
import { PaginationService } from 'src/app/shared/services/pagination/pagination.service';
import { FRAUDULENT_CASES, PaymentStatus } from 'src/app/shared/constants/payment-status';
import { ApplicationsService } from 'src/app/shared/services/applications/applications.service';
import { ActivatedRoute } from '@angular/router';

declare type Filters = {
    search: string,
    startDate: string,
    endDate: string,
    paymentType: string | number,
    paymentStatus: string,
    environment: string,
    application: string,
    paymentUser: string,
    actionRequired: string;
    section: string,
    pageNumber: number,
    pageSize: number
};

type UpdatablePaymentStatusType = typeof PaymentStatus.SESSION_STATUS.PAYMENT_CREATED | typeof PaymentStatus.SESSION_STATUS.PAYMENT_CANCELLED;
type PaymentUpdateOptions = {
    newStatus: UpdatablePaymentStatusType,
    transferReason: string,
    reason: string
};

@Injectable({
    providedIn: 'root'
})
export class PaymentsService {
    readonly filtersState = new State<Filters>({
        search: '',
        startDate: this._threeMonthsAgo(),
        endDate: '',
        paymentType: '',
        paymentStatus: '',
        environment: 'production',
        application: '',
        paymentUser: '',
        actionRequired: '',
        section: '',
        pageNumber: 1,
        pageSize: 10
    });

    readonly events = {
        reloadPayment: new Subject<void>(),
        reloadPaymentsList: new Subject<void>(),
        initiateRefund: new Subject<Payment>(),
    };

    public loadingPayments = false;

    // We set a default startDate if the get payments query returns a timeout error
    private defaultStartDate: string;

    private paymentChanges = new Subject<Payment>();

    constructor(
        private http: HttpClient,
        private companies: CompaniesService,
        private paginationService: PaginationService,
        private language: LanguageService,
        private applicationService: ApplicationsService,
        private route: ActivatedRoute,
    ) { }

    getPayments(reference, paymentTypeId, startDate, endDate, paymentStatus, environment, applicationId, userId, actionRequired, pageNumber, pageSize, section = ''): Observable<any> {
        this.loadingPayments = true;
        return this.companies.getCurrentCompanyId().pipe(
            switchMap(companyId => {
                const params = {
                    'filter[company_id]': companyId,
                    'filter[reference]': reference,
                    'filter[payment_type_id]': paymentTypeId,
                    'filter[start_date]': startDate || '',
                    'filter[end_date]': endDate || '',
                    'filter[payment_status]': paymentStatus,
                    'filter[environment]': environment,
                    'filter[application_id]': applicationId,
                    'filter[user_id]': userId,
                    'filter[action_required]': actionRequired,
                    'filter[section]': section,
                    'page[number]': pageNumber,
                    'page[size]': pageSize
                };
                return this.http.get(`/api/companies/${companyId}/payments`, { params });
            }),
            map((res: any) => {
                this.loadingPayments = false;
                return { totalSessions: res.totalSessions, totalPages: res.totalPages, payments: res.payments.map(p => new Payment(p)) };
            })
        );
    }

    getPaymentSections(): Observable<PaymentSection[]> {
        return this.companies.getCurrentCompanyId().pipe(
            switchMap(companyId => this.http.get(`/api/companies/${companyId}/payments/payment-sections`)),
            map((sections: any[]) => sections.map(s => new PaymentSection(s)))
        );
    }

    exportPayments(reference, paymentTypeId, startDate, endDate, paymentStatus, environment, applicationId, userId): Observable<object> {
        return this.companies.getCurrentCompanyId().pipe(
            switchMap(companyId => {
                const params = {
                    'filter[company_id]': companyId,
                    'filter[reference]': reference,
                    'filter[payment_type_id]': paymentTypeId,
                    'filter[start_date]': startDate,
                    'filter[end_date]': endDate,
                    'filter[payment_status]': paymentStatus,
                    'filter[environment]': environment,
                    'filter[application_id]': applicationId,
                    'filter[user_id]': userId
                };

                return this.http.get(`/api/companies/${companyId}/payments/export`, { params });
            })
        );
    }

    getPayment(sessionId, environment): Observable<Payment> {
        return this.companies.getCurrentCompanyId().pipe(
            switchMap(companyId => {
                const params = {
                    'filter[company_id]': companyId,
                    'filter[environment]': environment
                };

                return this.http.get(`/api/companies/${companyId}/payments/${sessionId}`, { params });
            }),
            map(payment => new Payment(payment))
        );
    }

    getPrintPayment(sessionId, environment): Observable<any> {
        return this.companies.getCurrentCompanyId().pipe(
            switchMap(companyId => {
                const params = {
                    'filter[environment]': environment
                };

                return this.http.get(`/api/companies/${companyId}/payments/${sessionId}.pdf`, { params, headers: { accept: 'application/pdf' }, responseType: 'blob' });
            })
        );
    }

    cancelRTP(payment: Payment): Observable<any> {
        const params = { environment: payment.environment };

        return this.companies.getCurrentCompanyId().pipe(
            switchMap(
                companyId => this.http.delete(`/api/companies/${companyId}/payments/rtp/${payment.sessionId}`, { params })
            )
        );
    }

    cancelRFR(payment: Payment): Observable<any> {
        const params = { environment: payment.environment };

        return this.companies.getCurrentCompanyId().pipe(
            switchMap(
                companyId => this.http.delete(`/api/companies/${companyId}/payments/request-for-payout/${payment.sessionId}`, { params })
            )
        );
    }

    cancelRefund(payment: Payment): Observable<any> {
        const params = { environment: payment.environment };

        return this.companies.getCurrentCompanyId().pipe(
            switchMap(
                companyId => this.http.delete(`/api/companies/${companyId}/payments/refund/${payment.sessionId}`, { params })
            )
        );
    }

    updatePaymentStatus(payment: Payment,  { newStatus, transferReason, reason }: PaymentUpdateOptions): Observable<any> {
        const params = { environment: payment.environment };
        const body = {
            appId: payment.application_id,
            status: newStatus,
            transfer_reason: transferReason,
            reason
        };

        return this.companies.getCurrentCompanyId().pipe(
            switchMap(
                companyId => this.http.patch(`/api/companies/${companyId}/payments/${payment.sessionId}`, body, { params })
            )
        );
    }

    updateNetTerms(sessionId: string, params: { appId: string, status?: string, amount?: string, netAmount?: string, invoiceReference?: string }): Observable<any> {
        const body = {
            appId: params.appId,
            ...(params.status && {status: params.status}),
            ...(params.amount && {amount: params.amount}),
            ...(params.netAmount && {net_amount: params.netAmount}),
            ...(params.invoiceReference && {invoice_reference: params.invoiceReference}),
        };
        return this.companies.getCurrentCompanyId().pipe(
            switchMap(
                companyId => this.http.patch(`/api/companies/${companyId}/payments/${sessionId}`, body)
            )
        );
    }

    initFilters({ reset }: {reset?: boolean} = {}): void {
        const queryParams = reset ? {} : this.route.snapshot.queryParams;
        this.filtersState.update({
            search: queryParams.search || '',
            startDate: reset ? '' : queryParams.startDate || this._threeMonthsAgo(),
            endDate: queryParams.endDate || '',
            paymentType: Number(queryParams.paymentType) || '',
            paymentStatus: queryParams.paymentStatus || '',
            environment: queryParams.environment || 'production',
            application: queryParams.application || '',
            paymentUser: queryParams.paymentUser || '',
            actionRequired: queryParams.actionRequired || '',
            section: queryParams.section || '',
            pageSize: Number(queryParams.pageSize) || Number(this.paginationService.getPaymentPageSize()) || 10,
            pageNumber: Number(queryParams.pageNumber) || 1
        });
    }

    setDefaultStartDate(delta?: { unit: 'days' | 'months', time: number }): void {
        const date = new Date();
    
        switch (delta?.unit) {
            case 'days':
                date.setDate(date.getDate() - delta.time);
                break;
            case 'months':
                date.setMonth(date.getMonth() - delta.time);
                break;
        }
    
        this.defaultStartDate = formatDate(date, 'yyyy-MM-dd', this.language.getCurrentLang());
    }

    _threeMonthsAgo(): string {
        this.setDefaultStartDate({ unit: 'months', time: 3 });
        return this.defaultStartDate;
    }

    getDefaultStartDate(): string {
        return this.defaultStartDate;
    }

    getPaymentTypes(): Observable<object> {
        return this.companies.getCurrentCompanyId().pipe(
            switchMap((companyId) => {
                return this.http.get(`/api/companies/${companyId}/payments/types`);
            })
        );
    }

    getPaymentStatus(): Observable<object> {
        return this.companies.getCurrentCompanyId().pipe(
            switchMap((companyId) => {
                return this.http.get(`/api/companies/${companyId}/payments/status`);
            })
        );
    }

    replayApplicationWebhooks(payment: Payment): Observable<any> {
        const params = { environment: payment.environment };

        return this.companies.getCurrentCompanyId().pipe(
            switchMap(
                companyId => this.http.post(`/api/companies/${companyId}/payments/replay-webhooks/${payment.sessionId}`, { params })
            )
        );
    }

    getPaymentsVolume(): Observable<any> {
        return this.companies.getCurrentCompanyId().pipe(
            switchMap(companyId => this.http.get(`/api/companies/${companyId}/payments/volume`))
        );
    }

    validateRFR(payment: Payment): Observable<any> {
        const body = { environment: payment.environment };
        return this.companies.getCurrentCompanyId().pipe(
            switchMap(
                companyId => this.http.post(`/api/companies/${companyId}/payments/request-for-payout/${payment.sessionId}/validate`, body)
            )
        );
    }

    replayRtp(sessionId): Observable<any> {
        return this.companies.getCurrentCompanyId().pipe(
            switchMap((companyId) => {
                return this.http.post(`/api/companies/${companyId}/payments/rtp/${sessionId}/replay`, {});
            })
        );
    }

    replayRfp(sessionId): Observable<any> {
        return this.companies.getCurrentCompanyId().pipe(
            switchMap((companyId) => {
                return this.http.post(`/api/companies/${companyId}/payments/request-for-payout/${sessionId}/replay`, {});
            })
        );
    }

    sendReminder(sessionId, reminderReason): Observable<any> {
        return this.companies.getCurrentCompanyId().pipe(
            switchMap((companyId) => this.http.post(`/api/companies/${companyId}/payments/${sessionId}/reminders`,{
                data: {
                    attributes: {
                        reason: reminderReason
                    }
                }
            })),
        );
    }

    getPaymentChanges(): Observable<Payment> {
        return this.paymentChanges.asObservable();
    }

    notifyPaymentChange(payment: Payment): void {
        this.paymentChanges.next(payment);
    }

    isPaymentFraudulent(payment): boolean {
        const fraudulentStatuses = [FRAUDULENT_CASES.FRAUD_SUSPECTED, FRAUDULENT_CASES.FRAUD_SUSPECTED_AUTOREFUND, FRAUDULENT_CASES.FRAUD_REFUND, FRAUDULENT_CASES.FRAUD_SUSPECTED_NO_OVERRIDE];
        return fraudulentStatuses.includes(payment?.fraudDetails) || payment?.transferReason === 'fraudulent_originated';
    }

    isFraudulentPremium(payment): Observable<boolean> {
        return this.applicationService.loadApplications().pipe(map((applications) => {
            const isFraudGuardEnabled = applications?.find(app => app?.id === payment?.application_id)?.isFraudGuardEnabled;
            return isFraudGuardEnabled && (this._isPFSFlagged(payment) || payment?.fraudDetails === FRAUDULENT_CASES.FRAUD_SUSPECTED);
        })); 
    }

    // PFS flagged => premium
    public _isPFSFlagged(payment) {
        return payment?.status === 'payment_unsuccessful' && payment?.transferState === 'rejected' && payment?.transferReason === 'fraud_suspected';
    }
}
